// Generated by Framer (0b5b7f5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["QI_37KbMo", "DN5mOVoDR", "nGw5oSJJ7"];

const serializationHash = "framer-FNgIy"

const variantClassNames = {DN5mOVoDR: "framer-v-1rgtlij", nGw5oSJJ7: "framer-v-1w77frv", QI_37KbMo: "framer-v-10i32yn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "QI_37KbMo", Phone: "nGw5oSJJ7", Tablet: "DN5mOVoDR"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "QI_37KbMo"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "QI_37KbMo", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-10i32yn", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"QI_37KbMo"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({DN5mOVoDR: {"data-framer-name": "Tablet"}, nGw5oSJJ7: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FNgIy.framer-5atpdz, .framer-FNgIy .framer-5atpdz { display: block; }", ".framer-FNgIy.framer-10i32yn { height: 557px; overflow: hidden; position: relative; width: 1140px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 557
 * @framerIntrinsicWidth 1140
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"DN5mOVoDR":{"layout":["fixed","fixed"]},"nGw5oSJJ7":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerk7GIArZl6: React.ComponentType<Props> = withCSS(Component, css, "framer-FNgIy") as typeof Component;
export default Framerk7GIArZl6;

Framerk7GIArZl6.displayName = "Herovideo";

Framerk7GIArZl6.defaultProps = {height: 557, width: 1140};

addPropertyControls(Framerk7GIArZl6, {variant: {options: ["QI_37KbMo", "DN5mOVoDR", "nGw5oSJJ7"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerk7GIArZl6, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})